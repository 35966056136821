import React, {useContext} from "react";
import '../styles/Header.scss';
import Menu from "../components/Menu";
import MyOrder from "../containers/MyOrder";
import menu from '../assets/icons/icon_menu.svg';
import logo from '../assets/logos/logo_yard_sale.svg';
import shoppingCart from '../assets/icons/icon_shopping_cart.svg';
import ProductDetail from "../containers/ProductDetail";  
import {AppContext} from '../context/AppContextProvider';
import {AppToggleContext} from '../context/AppContextProvider';
/* import useToggle from "../hooks/useToggle"; */

const Header = () => {
  const { state } = useContext(AppContext);
  
  const {
    toggle, 
    setToggle, 
    toggleOrders, 
    setToggleOrders,
    toggleProductDetail
  } = useContext(AppToggleContext);
  
  const handleToggle = () => {
    setToggle(!toggle);
  }

  return (
    <nav>
      <div className="navbar-container">
        <img src={menu} alt="menu" className="menu" />

        <div className="navbar-left">
          <img src={logo} alt="logo" className="nav-logo" />

          <ul>
            <li>
              <a href="/">All</a>
            </li>
            <li>
              <a href="/">Clothes</a>
            </li>
            <li>
              <a href="/">Electronics</a>
            </li>
            <li>
              <a href="/">Furnitures</a>
            </li>
            <li>
              <a href="/">Toys</a>
            </li>
            <li>
              <a href="/">Others</a>
            </li>
          </ul>
        </div>

        <div className="navbar-right">
          <ul>
            <li className={`navbar-email ${toggle && 'navbar-email-active'}`} 
              onClick={handleToggle}>
              brayan@example.com
            </li>
            <li className="navbar-shopping-cart" 
                onClick={() => setToggleOrders(!toggleOrders)}
            >
              <img src={shoppingCart} alt="shopping cart" />
              {state.cart.length > 0 ? <div>{state.cart.length}</div> : null}
            </li>
          </ul>
        </div>
        {toggle && <Menu />}
        {toggleOrders && <MyOrder/>}
        {toggleProductDetail && <ProductDetail />} 
      </div>
    </nav>
  );
};

export default Header;
