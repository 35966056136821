import { useState } from 'react';

const useToggle = () => {
    const [toggle, setToggle] = useState(false);
    const [toggleOrders, setToggleOrders] = useState(false);
    const [toggleProductDetail, setToggleProductDetail] = useState(false);

    return {
        toggle,
        setToggle,
        toggleOrders,
        setToggleOrders,
        toggleProductDetail, 
        setToggleProductDetail
    }
}

export default useToggle;