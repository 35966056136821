import React from 'react';
import useInitialState from '../hooks/useInitialState';
import useToggle from '../hooks/useToggle';

const AppContext = React.createContext();
const AppToggleContext = React.createContext();

const AppContextProvider = ({children}) => {
    const initialState = useInitialState();
    const {
        toggle,
        setToggle,
        toggleOrders,
        setToggleOrders,
        toggleProductDetail, 
        setToggleProductDetail
    } = useToggle();
    
    return (
        <AppContext.Provider value={initialState}>
            <AppToggleContext.Provider value={{
                toggle,
                setToggle,
                toggleOrders,
                setToggleOrders,
                toggleProductDetail, 
                setToggleProductDetail
            }}>
                {children}
            </AppToggleContext.Provider>
        </AppContext.Provider>
    );
}

export {AppContextProvider, AppContext, AppToggleContext};