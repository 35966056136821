import React from 'react';
import '../styles/NotFound.scss';

const NotFound = () => {
    return (
        <div className="error-container">
            <div className="error-container__items">
                <h1>0</h1>
                <h2>UH OH! You're lost.</h2>
                <p>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</p>
                <button type='button'>Home</button>
            </div>
        </div>
    );
}

export default NotFound;