import React, {useContext} from 'react';
import '../styles/ProductItem.scss';
import addCartBtn from '../assets/icons/bt_add_to_cart.svg';
import {AppContext, AppToggleContext} from '../context/AppContextProvider';
const ProductItem = ({product}) => {
	const { addToCart, productDetail, replaceProductDetail, state } = useContext(AppContext);
	
	const handleClick = item => {
		addToCart(item);
	}

	const { setToggleProductDetail } = useContext(AppToggleContext);
	const openProductDetail = (item) => {
		if(state.productDetail.length > 0) {
			replaceProductDetail();
			productDetail(item);
		} else {
			productDetail(item);
		}
		setToggleProductDetail(true);
	}
	return (
		<div className="ProductItem">
			<img src={product.images[0]} 
			alt={product.title}
			onClick={() => openProductDetail(product)}
			/>
			<div className="product-info">
				<div>
					<p>${product.price.toFixed(2)}</p>
					<p>{product.title}</p>
				</div>
				<figure onClick={() => handleClick(product)}>
					<img src={addCartBtn} alt="" />
				</figure>
			</div>
		</div>
	);
}

export default ProductItem;