import React, { useContext } from 'react';
import ProductInfo from '../components/ProductInfo';
import '../styles/ProductDetail.scss';
import iconClose from '../assets/icons/icon_close.png';
import { AppToggleContext, AppContext } from '../context/AppContextProvider';
const ProductDetail = () => {
	const { setToggleProductDetail } = useContext(AppToggleContext);
	const closeProductDetail = () => {
		setToggleProductDetail(false);
	}
	const { state } = useContext(AppContext);
	
	return (
		<aside className="ProductDetail">
			<div className="ProductDetail-close">
				<img src={iconClose} alt="close"
					onClick={closeProductDetail} 
				/>
			</div>
			{
				state.productDetail.map(product => (
					<ProductInfo product={product}/>
				))
			}
		</aside>
	);
}

export default ProductDetail;
