import React, { useContext } from 'react';
import OrderItem from '../components/OrderItem';
import {AppContext, AppToggleContext} from "../context/AppContextProvider";
import '../styles/MyOrder.scss';
import flechita from '../assets/icons/flechita.svg';
const MyOrder = () => {
	const { state } = useContext(AppContext);

	const sumTotal = () => {
		const reducer = (accumalator, currentValue) => accumalator + currentValue.price;
		const sum = state.cart.reduce(reducer, 0);
		return sum;
	}

	const { setToggleOrders } = useContext(AppToggleContext);
	return (
		<aside className="MyOrder">
			<div className="title-container">
				<img src={flechita} alt="arrow"
					onClick={() => setToggleOrders(false)} 
				/>
				<p className="title">My order</p>
			</div>
			<div className="my-order-content">
				{state.cart.map((product, index )=> (
					<OrderItem 
						indexValue={index}
						key={index}
						product={product} 
					/>
				))}
				<div className="order">
					<p>
						<span>Total</span>
					</p>
					<p>${sumTotal()}</p>
				</div>
				<button className="primary-button">
					Checkout
				</button>
			</div>
		</aside>
	);
}

export default MyOrder;