import { useState } from "react";

const initialState = {
    cart: [],
    productDetail: [],
}
const useInitialState = () => {
    const [state, setState] = useState(initialState);
    const addToCart = (payload) => {
        setState({
            ...state,
            cart: [...state.cart, payload]
        });
    };

    const removeFromCart = (indexValue) => {
        setState({
          ...state,
          cart: state.cart.filter((product,index) => index !== indexValue),
        })
    }
    const productDetail = (payload) => {
        setState({
            ...state,
            productDetail: [...state.productDetail, payload]
        });
    }
    const replaceProductDetail = () => {
        setState({
            productDetail: state.productDetail.splice(0,1)
        });
    }
    
    return {
        state,
        addToCart,
        removeFromCart,
        productDetail,
        replaceProductDetail,
    }
}

export default useInitialState;